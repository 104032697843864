import React from 'react'
import TextOverlapImage from '../../components/TextOverlapImage/TextOverlapImage'

const OverlappedImageText = ({ items }) => {

    return (
        <>
            {items.map((item,i) => (
                <div key={item.heading} className="p-section">
                    <TextOverlapImage
                        heading={item.heading}
                        text={item.blurb}
                        image={item.imageId}
                        button={item.button}
                        reverse={item.isReversed}
                    />
                </div>
            ))}
        </>
    )
}

export default OverlappedImageText
