import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import TheaterVideo from "@bit/azheng.joshua-tree.theater"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import ButtonGroupMap from '../../components/Button/ButtonGroupMap';
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()



// Only is set up for 2 or 5 docs
function MeetOurDoctors(props) {
  if (!props.post.meetOurDoctors.hasThisSection) return <></>
  
  const data = props.post.meetOurDoctors
  const docLength = data.images.length
  const esDocClass = props.language === "es" ? "doc-info-es" : ""

  const mainClasses = classNames(
    "body-section", 
    "doc-info", {
      "color-back": false
    }
  );

  const esMainClasses = classNames(
    "doc-info-es",
    "body-section", 
    "doc-info", {
      "color-back": !props.language === "es"
    }
  );


  // split doc images into 2 arrays
  let docChunks = [[],[]]

  // if 4 or 5 doctors, top row gets 2. else show 3
  let arrayLimit = 3
  if (docLength === 5 || docLength === 4) {
    arrayLimit = 2
  }

  data.images.forEach((dr, i) => {
    if (docChunks[0].length >= arrayLimit) {
      docChunks[1].push(dr)
    } else {
      docChunks[0].push(dr)
    }
  })

  return (
    <section className={` ${props.language === "es" ? esMainClasses : mainClasses}`}>
      <div className="columns">
        <div className="column is-4"></div>
        <div className="column has-text-centered-tablet">
          <MarkdownViewer markdown={data.text} />

          </div>
        <div className="column is-4"></div>
      </div>
          {data.useButtons &&
            <ButtonGroupMap
            buttons={data.buttons}
            />
          }

      {!data.useButtons &&
        <div className={`our-docs is-centered has-${docLength}-docs`}>
          <DoctorImages doctors={docChunks} />
        </div>
      }
    </section>
  )
}

function DoctorImages({ doctors }) {
  
  const styles = {
    captions: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    learnMore: {
      fontWeight: 600,
      fontSize: '14px',
      textDecoration: 'underline'
    },
    docName: {
      fontSize: '14px'
    }
  }

  const columnClasses = classNames(
    "column",
    "doc-column",
    {
      "is-8": doctors[0].length === 1,
      "is-7": doctors[0].length === 2,
      "is-6": doctors[0].length >= 3,
    }
  )

  return (
    <>
      <div className="columns is-centered">
        {doctors[0] && doctors[0].map((image, i) => (
          <React.Fragment key={i}>
          <div className={columnClasses}>
            <Link to={image.doctor.url}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={image.doctor.imageId}
                width="auto"
                responsive
                className="meet-our-doctor"
              ></ImageMeta>
            </Link>

            <div className="docs-2-3-caption" style={styles.captions}>
              <Link to={image.doctor.url}>
                <h5 className="image-caption doctor" style={styles.docName}>
                  {image.doctor.caption}
                </h5>
              </Link>

              <Link to={image.doctor.url}>
                <h5 className="image-caption doctor learn" style={styles.learnMore}>
                  Learn More
                </h5>
              </Link>
            </div>

          </div>
          {i !== doctors[0].length - 1 && <div className="column is-1"></div>}
          </React.Fragment>
        ))}
      </div>

      {doctors[1].length >= 1 &&
        <>
          <div className="mt-40__desktop"></div>

          <div className="columns is-centered">
            {doctors[1] && doctors[1].map((image, i) => (
              <React.Fragment key={i}>
              <div className={columnClasses}>
                <Link to={image.doctor.url}>
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={image.doctor.imageId}
                    width="auto"
                    responsive
                    className="meet-our-doctor"
                  ></ImageMeta>
                </Link>
                
                <div className="docs-2-3-caption" style={styles.captions}>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor" style={styles.docName}>
                      {image.doctor.caption}
                    </h5>
                  </Link>

                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor learn" style={styles.learnMore}>
                      Learn More
                    </h5>
                  </Link>
                </div>

              </div>
              {i !== doctors[1].length - 1 && <div className="column is-1"></div>}
              </React.Fragment>
            ))}
          </div>
        </>
      }

    </>
  )
}

export default MeetOurDoctors
